<template>
  <div>
    <div class="app-wrapper">
      <div class="container py-5">
        <div class="col-lg-10 mx-auto p-0 app-wrapper__content">
          <div class="empty-links text-center py-5 col-lg-6 mx-auto">
            <h3 class="ana_h3 ana_text-dark py-2">
              Save time and shorten links directly from your own website with
              our flexible API
            </h3>
            <p class="ana_paragraph ana_text-grey pb-5">
              This is still in development
            </p>
            <img
              src="@/assets/img/app/api.svg"
              class="d-block mx-auto my-4"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "API",
  metaInfo: {
    title: "API - Ana app",
  },
};
</script>
